$(() => {
// Variable set up

  const $body = $('body')

  $body.addClass('state--nav-closed')

  // It's header time

  const navigationToggle = document.querySelector('#nav-toggle')
  const headerFocusableWrappers = document.querySelectorAll('.js-trap-focus')

  let savedScrollPos
  let navOpen = false

  function toggleMenuVisibility (value) {
    navigationToggle.setAttribute('aria-expanded', value)
    $body.toggleClass('state--nav-open state--nav-closed')
    trapFocus(headerFocusableWrappers, value ? navigationToggle : null)
  }

  $('#nav-toggle').click(function (e) {
    e.preventDefault()
    if (navOpen) {
      closeNav()
      toggleMenuVisibility(false)
    } else {
      openNav()
      toggleMenuVisibility(true)
    }
    navOpen = !navOpen
  })

  function openNav () {
    savedScrollPos = window.pageYOffset
  }

  function closeNav () {
    window.scrollTo(0, savedScrollPos)
  }

  // Esc closes header menu

  const KEYCODE_ESC = 27
  $(document).keyup(function (e) {
    if (e.keyCode === KEYCODE_ESC) {
      if (navOpen) {
        closeNav()
        toggleMenuVisibility(false)
        navOpen = !navOpen
      }
    }
  });

  // Compact header on scroll

  (function () {
    let scroll = 0

    $(window).scroll(function () {
      scroll = $(window).scrollTop()

      if (scroll >= 60) {
        $body.addClass('state--header-compact')
      } else {
        $body.removeClass('state--header-compact')
      }
    })
  })()
})

//
// trapFocusFunction for header/nav

let firstElement
let lastElement

/*
* Config for the focus trap
* @param {array} parents - an array of elements to which the focus should be constrained
* @param {HTMLElement} elementToFocus - an element to focus when the trap is activated.
* Focus trap will be removed from the parent when no elementToFocus is passed
*/
function trapFocus (parents, elementToFocus) {
  // If no elementToFocus is passed, remove all event listeners and get out of here
  if (!elementToFocus) {
    // Trap off. Remove event listeners
    window.removeEventListener('keydown', handleTrapKeydown)
    return
  }

  const elements = []
  // Otherwise find all focusable elements inside the parent element passed to this function
  Array.from(parents).forEach(item => {
    elements.push(...getFocusableElements(item))
  })

  firstElement = elements[0]
  lastElement = elements[elements.length - 1]
  // Trap on
  elementToFocus.focus()
  window.addEventListener('keydown', handleTrapKeydown)
}

/*
* Get focusable elements inside a wrapper
* @param {HTMLElement} element - the wrapper element to be searched through for focusable items
* @returns {array} focusable
*/
function getFocusableElements (element) {
  let focusable = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])')
  // Filter to remove elements that are visually hidden with css
  focusable = Array.from(focusable).filter(item => {
    return window.getComputedStyle(item).display !== 'none'
  })
  return focusable
}

/*
* Keydown event handler for the focus trap
* Checking which key was pressed, handling looping, etc.
*/
const KEY_TAB = 'Tab'

function handleTrapKeydown (e) {
  const isTabPressed = (e.key === KEY_TAB)
  if (!isTabPressed) {
    return
  }
  if (e.shiftKey) {
    if (document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  } else {
    if (document.activeElement === lastElement) {
      firstElement.focus()
      e.preventDefault()
    }
  }
}

